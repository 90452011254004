$(document).ready(function() {
    var hWin = $(window).height();
    var wWin = $(window).width();
    var hHeader = $('.about .main-header').outerHeight(true);
    var hBanner = hWin - hHeader;
    $('.about .group-history').height(hBanner);
    ///////////////////
    var hFooter = $('.about .footer').outerHeight(true);
    var hMap = hWin - hFooter;
    $('.about .group-csr').height(hMap);
});
$(window).resize(function() {
    var hWin = $(window).height();
    var wWin = $(window).width();
    var hHeader = $('.about .main-header').outerHeight(true);
    var hBanner = hWin - hHeader;
    $('.about .group-history').height(hBanner);
    ///////////////////
    var hFooter = $('.about .footer').outerHeight(true);
    var hMap = hWin - hFooter;
    $('.about .group-csr').height(hMap);
});